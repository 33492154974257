*{
  margin: 0;
  padding: 0;
  border: none;
  outline: 0;
}
img {
  vertical-align: middle;
  max-width: 100%;
  border-style: none;
}
html, body {
  color: #2c2d2d;
  margin-bottom: 0;
  overflow-x: hidden !important
}
body {
  font-family: 'Montserrat', sans-serif;

  font-size: 14px;
  line-height: 24px;
  color: #2c2d2d;
}