
a {
  text-decoration: none;
}

a:hover {
  
  text-decoration: none;
  cursor: pointer;
}
.menu
{
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}
.menu .navbar-nav .nav-item a
{
  color: #000;
  font-size: 14px;
  line-height: normal;
  padding: 0 5px;
  font-weight: 700;
  text-decoration: none;
}
.menu .navbar-nav .nav-item
{
  margin: 0 15px;
}
.menu .navbar-nav .nav-item a.active
{
  color: #f10111;
}
.home-sec-1
{
  background: url(../src/components/images/home-banner.png) no-repeat center center / cover;
 
  padding: 250px 0; 
}
.banner-left
{
  position: relative;
  display: block;
  text-align: center;
 
}
.flyIn
{
  position: absolute;
  display: block;
  width: 100%;
  height: auto;
  z-index: 9;
  top: -90px;
  animation-duration: 4.5s;
  transition: all .3s ease-in-out;
}

@keyframes flyIn {
  0% {
      transform: translateY(0)
  }

  50% {
      transform: translateY(1rem)
  }

  to {
      transform: translateY(0)
  }
}

.flyIn {
  animation-name: flyIn;
  animation-duration: 4.5s;
  animation-iteration-count: infinite;
  animation-delay: .1s
}
.banner-img h1
{
  font-size: 36px;
  line-height: 48px;
  color: #2c2d2d;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}
.banner-img p{
  font-size: 18px;
  line-height: 28px;
  color: #2c2d2d;
  font-weight: 400;
  margin-bottom: 2em;
}
.btn-them
{
  background: linear-gradient(to right, #434343 0%, black 100%);
  color:#fff;
  -webkit-transition: background 1s ease-out;  
  -moz-transition: background 1s ease-out;  
  -o-transition: background 1s ease-out;  
  transition: background 1s ease-out;  

}
.btn-them:hover
{  color:#fff;
  background-position: 148.5px;
}
.banner-btn
{
  font-size: 14px;
  line-height: normal;
  padding: 10px 20px;
  border-radius: 80px;
  margin-right: 1em;
  width: 150px;
}
.heading h2
{
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
  padding-bottom: 30px;
  margin-bottom: 40px;
  text-align: center;
  position: relative;
  color: #2c2d2d;
}
.home-sec-2 .heading h2:before
{
  position: absolute;
  display: block;
  content: "";

  background: url(../src/components/images/heading.png) no-repeat 0 0;
  background-size: cover;
  width: 85px;
  height: 8px;
  bottom: 0;
  left: 50%;
  margin-left: -42.5px
}
.bg-icon-1
{
  background: url(../src/components/images/icons/gift.svg) no-repeat top center;
  background-size: 70%;
}

.bg-icon-2
{
  background: url(../src/components/images/icons/paper-plane.svg) no-repeat top center;
  background-size: 60%;
}
.bg-icon-3
{
  background: url(../src/components/images/icons/Domain.png) no-repeat top center;
  background-size: 60%;
}

.bg-icon-4
{
  background: url(../src/components/images/icons/Design.png) no-repeat top center;
  background-size: 60%;
}

.bg-icon-5
{
  background: url(../src/components/images/icons/Content.png) no-repeat top center;
  background-size: 60%;
}

.bg-icon-6
{
  background: url(../src/components/images/icons/seo.png) no-repeat top center;
  background-size: 60%;
}
.bg-icon-7
{
  background: url(../src/components/images/icons/Security.png) no-repeat top center;
  background-size: 60%;
}
.bg-icon-8
{
  background: url(../src/components/images/icons/Testing.png) no-repeat top center;
  background-size: 60%;
}

.bg-icon-9
{
  background: url(../src/components/images/icons/Training.png) no-repeat top center;
  background-size: 60%;
}


.bg-icon-10
{
  background: url(../src/components/images/icons/Analytics.png) no-repeat top center;
  background-size: 60%;
}
.bg-icon-11
{
  background: url(../src/components/images/icons/Additional.png) no-repeat top center;
  background-size: 60%;
}
.headroom {
  top: 0;
  left: 0;
  right: 0;
  z-index: 99 !important;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}
.headroom--unpinned .fixed-top
{
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  margin-top: -280px;
  background-color: #fff;
}
.headroom--pinned .fixed-top
{
  background-color: #fff;
  box-shadow: 0 3px 59px #00000026;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;


}
.btn-red
{
 


  transition: background 1s ease-out;


  --bs-btn-color: #fff;
  --bs-btn-bg: #f10111;
  --bs-btn-border-color: #f10111;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #88040d;
  --bs-btn-hover-border-color: #f10111;
  --bs-btn-focus-shadow-rgb: 60,153,110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #f10111;
  --bs-btn-active-border-color: #88040d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #f10111;
  --bs-btn-disabled-border-color: #f10111;

}
.headroom--pinned .fixed-top .navbar-brand img
{
  width: 150px;
  transition: all .7s;
}
.home-sec-2-row h3{
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
}
.home-sec-2-row p{
  text-align: justify;

}
.home-sec-2-row ul
{
  padding-left: 1rem;
}
.section-divider-bg-right
{
  background: url('../src/components/images/section-divider-bg-right.png')no-repeat center center / contain;
  padding: 6em 0;
  margin: 3em 0;
}
.heading-left h2
{
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
  color: #2c2d2d;
}
.section-divider-bg-left
{
  background: url('../src/components/images/section-divider-bg-left.png')no-repeat center center / contain;
  padding: 6em 0;
  margin: 3em 0;
}
.Services-footer
{
  padding: 0;
  list-style: none;
  margin-left: 1.5em;
}
.Services-footer li
{
  position: relative;
  margin-bottom: 0.7em;
}
.Services-footer li::after
{
content: '';
position: absolute;
background: url(../src/components/images/min-icon.png)no-repeat center center / contain;
left: -20px;
width: 12px;
height: 12px;
top: 6px;
}

.footer-heading
{
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  margin-left: 6px;
}
.footer
{
  margin: 4em 0 2em 0;
}
.footer-map
{
  padding: 0;
  list-style: none;
}.footer-map li a
{
  display: flex;
  align-items: center;
  text-decoration: none;    color: #2c2d2d;

  margin-bottom: 1em;
  
}
.footer-map li img
{
  width: 25px;
  margin-right: 1em;

}
.copy-write
{
  border-top: 1px solid #d7deee;
  padding-top: 2em;
  margin-bottom: 0rem;
  text-align: center;
}
.home-sec-6
{
  background: #f10111 url(../src/components/images/cta-bg.png)no-repeat center center / cover;
  color: #fff;
  text-align: center;
  padding: 5em 0;

}
.abt-sec3
{
  margin-bottom: 5em;
}
.home-sec-6 h3{
  font-size: 28px;
  line-height: 42px;
  font-weight: 600;
}.home-sec-6 a
{
  background-color: #fff;
  padding: 1em;
  border-radius: 150px;
  text-decoration: none;
  color: #222;
  display: block;
  width: 200px;
  margin: 2em auto 0 auto;
}
.home-sec-6 a img
{
  width: 25px;
  margin-left: 10px;
  margin-bottom: 4px;
}
.abt-sec1 
{
  padding-top: 150px;
  background: #edf3ff;
}.abt-sec1 .breadcrumb
{
  padding: 1em;
  margin: 0;
  align-items: center;
}
.abt-sec1 .breadcrumb img
{
  width: 25px;
  margin-right: 10px;
}
.abt-sec1 .breadcrumb a
{
  color: #000;
  text-decoration: none;
}


.abt-sec2
{
  margin: 4em 0;
}
.abt-sec2 .about
{
  margin: 0 auto;
}
.abou-heading
{
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
  padding-bottom: 30px;
  margin-bottom: 10px;

  position: relative;
  color: #2c2d2d;
}
.abou-heading::after
{
  position: absolute;
  display: block;
  content: "";

  background: url(../src/components/images/heading.png) no-repeat 0 0;
  background-size: cover;
  width: 85px;
  height: 8px;
  bottom: 0;
  left: 0;
  margin-left: 0;
  z-index: 99;

}
.about-sec-2
{
  position: relative;
}.about-sec-2 .card

{
  position: absolute;
  width: 400px;
  margin: 0 1em;
  top: -100px;
  padding: 1.5em;
  border-width: 0;
  box-shadow: 0 0 20px #0000001a;
}
.quote
{
  width: 25px;
  margin-right: 10px;
  margin-top: 5px;


}

.about-sec-2 p
{
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0;
  display: flex;
  align-items: flex-start;

}
.Products-sec-1
{
  padding: 1em 0;
  margin: 3em 0;
}
.text-justify
{
  text-align: justify;
}
.profile
{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 2rem;
}




.profile-card {

  /*background: #fff;*/
  padding: 30px;
  border-radius: 50%;
   /*box-shadow: 0 0 10px #3336;*/
  transition: .6s;
}

.profile-card {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
 
}

.profile-card img {
  width: 100%;
  height: 100%;
  transition: .6s;
  z-index: 10;
  width: 100%;

  transition: .6s;
}

.profile-card:hover img {
  transform: translateY(-60px);
}


.profile-card:hover img {
  border-radius: 10px;
}

.caption {
  text-align: center;
  transform: translateY(-20px);
  opacity: 0;
  transition: .6s;
}
.profile-card a .caption
{
  color: #f10111;
  text-decoration: none;
}
.profile-card a .caption:hover
{
  color: #f10111;
  text-decoration: none;
}

.profile-card:hover .caption {
  opacity: 1;
}

.caption h3 {
  font-size: 32px;
  font-weight: 600;
}

.caption p {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  top: -25px;
  font-size: 15px;
  color: #0c52a1;
  
}

.abt-sec3 h4
{
  position: relative;
  margin-bottom:1em ;
}
.abt-sec3 p
{
  margin-bottom:1.5em ;
  text-align: justify;
  min-height: 80px;


}

.abt-sec3 h4::after
{
  content: '';
  position: absolute;
  width: 100px;
  height: 2px;
  background-color: #f10111;
  bottom: -5px;
  left: 0;
}

.abt-sec3 a
{
  background-color: #f10111;
  border-color: #c10014;
}
.Office img
{

  width: 22px;
  margin-left:10px;
}
.Office span.line
{
  width: 100px;
  height: 1px;
  display: inline-block;
  background-color: #000;
}

.Office h5
{
  color: #2c2d2d;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: end;
}

.Office p
{
  color: #2c2d2d;
  font-size: 14px;
}
.Office p a
{
  color: #000;
  font-size: 14px;
}

.Contact
{
padding: 3em;
}
.Contact .heading h2
{
  text-align: left;
}


.Contact .heading h2:before
{
  left: 7%;
}


.Contact .heading
{
  color: #2c2d2d;
  font-size: 14px;
}


input:focus ~ label, textarea:focus ~ label, input:valid ~ label, textarea:valid ~ label {
  font-size: 0.75em;
  color: #999;
  top: -5px;
  -webkit-transition: all 0.225s ease;
  transition: all 0.225s ease;
}

.styled-input {
  float: left;
  width: 275px;
  margin: 1rem 0;
  position: relative;
  border-radius: 4px;
}

@media only screen and (max-width: 768px){
  .styled-input {
      width:100%;
  }
}

.styled-input label {
  color: #999;
  padding: 1.3rem 30px 1rem 30px;
  position: absolute;
  top: 10px;
  left: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  pointer-events: none;
}

.styled-input.wide { 
  width: 650px;
  max-width: 100%;
}

input,
textarea {
  padding: 30px;
  border: 0;
  width: 100%;
  font-size: 1rem;
  background: #fafbfc;
  border: 1px solid #bac6e1;
  color: #2c2d2d;
  border-radius: 4px;
}

input:focus,
textarea:focus { outline: 0; }

input:focus ~ span,
textarea:focus ~ span {
  width: 100%;
  -webkit-transition: all 0.075s ease;
  transition: all 0.075s ease;
}

textarea {
  width: 100%;
  min-height: 15em;
}

.input-container {
  width: 750px;
  max-width: 100%;
  margin: 0px auto 0px auto;
}

.submit-btn {
  float: right;
  padding: 7px 35px;
  border-radius: 60px;
  display: inline-block;
  background-color: #4b8cfb;
  color: white;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.06),
            0 2px 10px 0 rgba(0,0,0,0.07);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.submit-btn:hover {
  transform: translateY(1px);
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.10),
            0 1px 1px 0 rgba(0,0,0,0.09);
}

@media (max-width: 768px) {
  .submit-btn {
      width:100%;
      float: none;
      text-align:center;
  }
}



@media only screen and (max-width: 700px) {
  .profile-card {
      margin-top: 45px;
  }

}

@media only screen and (min-width: 768px) and (max-width: 1024px)  
  {
    .about-sec-2 .card
    {
      position: relative;
      width: 90%;
      top: -70px;
    }
  }
  @media only screen and (min-width: 100px) and (max-width: 768px)  
{

  .about-sec-2 .card
  {
    position: relative;
    width: 90%;
    top: -50px;
  }
  .banner-left
  {
    margin-top: 2em;
  }
  .abt-sec3 p
  {
    min-height: auto;
  }
  .abt-sec3 a
  {
    margin-bottom: 2em;
  }
  .flyIn
  {
    top: -30px;
  }
  .navbar-brand img
  {
    width: 150px;
  }
  .menu .navbar-nav .nav-item
  {
    margin: 0.5em 15px;
  }
  .home-sec-1
  {
    padding: 80px 0;
  }
  .Office h5
  {
    justify-content: start;
  }
  .Office p
  {
    text-align: left!important;
  }
  .Contact .heading h2:before {
    left: 15%;
}
.navbar-collapse
{
  background-color: #d7deee;
}
}
.section__contact
{
  background: #f4f7fc url(../src/components/images/megamenu-bg.svg) no-repeat bottom left;

  padding: 3em;
}
.responsive-map{
overflow: hidden;
padding-bottom:30%;
position:relative;
height:0;
}
.responsive-map iframe{
left:0;
top:0;
height:100%;
width:100%;
position:absolute;
}
.active{
  color:#fcfcfc;
 }

 .container {
  margin: 2rem auto;
}

.gallery {
  display: grid;
  max-width: 100%;
  margin: 0 auto;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: minmax(150px, auto);
}

.gallery-item {
  max-width: 100%;
  border-radius: 5px;
  padding: 1em;
  transition: all 0.3s;
}
.gallery-item:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
}
.gallery-item img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.gallery-item .mask p
{
  margin: 0;
  text-align: center;
  color: #333;
}
.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;

  width: 50px;
}